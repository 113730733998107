import React, { Component } from "react";
import Main from "../Main";
import QuestionGender from "../SurveyQuestions/QuestionGender";
import QuestionPrefs from "../SurveyQuestions/QuestionPrefs";
import QuestionSpend from "../SurveyQuestions/QuestionSpend";
import { withAlert } from "react-alert";
import { getEmail, getUserUuid } from "../../utilities/tracking";
import { withRouter } from "react-router";
import { addCanonical } from "../../utilities/addTagsToHead";
import ApiFunc from "../ApiCalls/ApiFunc";
import { local, session } from "../../utilities/storage";
import QuestionFreq from "../SurveyQuestions/QuestionFreq";

class Survey extends Component {
	constructor () {
		super();

		this.state = {
			userUuid: getUserUuid(),
			responses: {
				"cbuk_survey_offer_prefs": [],
				"cbuk_survey_spend": [],
				"cbuk_survey_freq": []
			},
			currentQuestion: 1
		};

		this.updateResponses = this.updateResponses.bind(this);
		this.submitResponses = this.submitResponses.bind(this);
	}

	componentDidMount () {
		addCanonical();
	}

	submitResponses () {
		const { userUuid, responses } = this.state;

		let data = [];

		if (responses.cbuk_survey_age) {
			data.push({ name: "cbuk_survey_age", values: [responses.cbuk_survey_age] });
		}

		if (responses.cbuk_survey_user_gender) {
			data.push({ name: "cbuk_survey_user_gender", values: [responses.cbuk_survey_user_gender] });
		}

		if (responses.cbuk_survey_freq) {
			responses.cbuk_survey_freq.forEach(pref => {
				data.push({ name: pref, values: ["yes"] });
			});
		}

		if (responses.cbuk_survey_offer_prefs) {
			responses.cbuk_survey_offer_prefs.forEach(pref => {
				data.push({ name: pref, values: ["yes"] });
			});
		}

		if (responses.cbuk_survey_spend) {
			responses.cbuk_survey_spend.forEach(pref => {
				data.push({ name: pref, values: ["yes"] });
			});
		}

		const surveyResponses = { survey_responses: data };

		if (session.getItem("source") === "Google") {
			// send conversion to google tag manager
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ "event": "conversion_survey" });
			if (getEmail()) {
				window.dataLayer.push({ "email": getEmail() });
			}
		}

		// send microsoft ads event
		window.uetq = window.uetq || [];
		window.uetq.push("event", "signup", {});

		ApiFunc(`user/submit-survey-answers/${userUuid}`, "POST", surveyResponses)
			.then(() => {
				this.props.alert.success("Your survey answers have been saved successfully", {
					timeout: 5000,
					onClose: () => {
						this.props.history.push("/task/level-1/welcome-survey/done");
					}
				});
			})
			.catch(() => {
				this.props.alert.error("Failed to save survey", {
					timeout: 5000,
					onClose: () => {
						this.props.history.push("/task/level-1/welcome-survey/done");
					}
				});
			});
	}

	updateResponses (event) {
		if (event?.target?.type === "checkbox") {
			if (event.target.name === "all") {
				// uncheck all
				this.setState(prevState => ({
					responses: {
						...prevState.responses,
						[event.target.value]: []
					}
				}));
				return;
			}

			if (event.target.checked) {
				this.setState(prevState => (
					{
						responses: {
							...prevState.responses,
							[event.target.name]: [
								...prevState.responses[event.target.name],
								event.target.value
							]
						}
					}
				));
				return;
			}

			const filteredItems = this.state.responses[event.target.name].filter(key => key !== event.target.value);

			this.setState(prevState => ({
				responses: {
					...prevState.responses,
					[event.target.name]: filteredItems
				}
			}));
			return;
		}

		this.setState(prevState => (
			{
				responses: {
					...prevState.responses,
					[event.target.name]: event.target.value
				}
			}
		));
	}

	render () {
		const { userUuid, responses, currentQuestion } = this.state;

		if (!userUuid) {
			this.props.history.push("/");
		}

		const gdprYes = local.getItem("agreement_decision") === "yes";
		const totalQuestions = gdprYes ? 4 : 3;

		let description = <>
			<p className="survey-info">Your first task is easy... We've just got a few short questions to help
				us get to know you.</p>
			<p className="survey-instructions">{"Answer the " + totalQuestions + " short questions below to earn your" +
				" first £2.50."}</p>

		</>;
		let question = <></>;
		let buttonText = "Next Question";
		let buttonAction = () => {
			this.setState(prevState => ({
				currentQuestion: prevState.currentQuestion + 1
			}));
		};
		let buttonClass = "";

		if (currentQuestion === totalQuestions) {
			buttonText = "Finish Survey";
			buttonAction = () => {

				if (gdprYes && currentQuestion === 4 && responses.cbuk_survey_freq.length === 0) {
					this.props.alert.error("Please select at least one option");
					return;
				}

				this.setState({
					currentQuestion: 9
				});
				this.submitResponses();
			};
		}

		switch (currentQuestion) {
			case 1:
				question = <QuestionPrefs questionNumber={currentQuestion}
										  totalQuestions={totalQuestions}
										  responses={responses}
										  onClick={this.updateResponses} />;
				break;
			case 2:
				question = <QuestionGender questionNumber={currentQuestion}
										   totalQuestions={totalQuestions}
										   responses={responses}
										   onClick={this.updateResponses} />;
				break;
			case 3:
				question = <QuestionSpend questionNumber={currentQuestion}
										  totalQuestions={totalQuestions}
										  responses={responses}
										  onClick={this.updateResponses} />;
				break;
			case 4:
				question = <QuestionFreq questionNumber={currentQuestion}
										 totalQuestions={totalQuestions}
										 responses={responses}
										 onClick={this.updateResponses} />;
				break;
			case 9:
				description = <></>;
				question = <>
					<h2>Thanks!</h2>
					<p>Thank you for your answers.</p>
					<p>Taking you back to <a href="https://www.cashback.co.uk">cashback.co.uk</a> to complete
						your next task...</p>
				</>;
				buttonClass = "hide";
				break;
		}

		return (
			<Main className="survey-container"
				  headerClassName="main-survey"
				  footerClassName="main-survey"
				  showH1={false}>
				<header className="survey-header">
					<img className="header-lady"
						 src="https://cashback-public.s3.eu-west-2.amazonaws.com/homepage/mobilewoman.svg" alt="Lady" />
					<h1 className="header-text">Welcome to your first task!</h1>
				</header>
				<main className="survey">
					{description}

					<div className="question-and-options">
						{question}
					</div>

					<button className={"survey-button " + buttonClass} onClick={buttonAction}>{buttonText}</button>
				</main>
			</Main>
		);
	}
}

export default withAlert()(withRouter(Survey));